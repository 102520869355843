<template>
  <q-page padding>
    <q-toolbar class="q-pr-none q-mb-md column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> Faturamento Advertiser </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Deeplink" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <header-advertiser-invoice />
    <list-advertiser-invoice />
    <form-advertiser-invoice />
  </q-page>
</template>

<script>
import HeaderAdvertiserInvoice from '@/components/advertiser/HeaderAdvertiserInvoice.vue';
import ListAdvertiserInvoice from '@/components/advertiser/ListAdvertiserInvoice.vue';
import FormAdvertiserInvoice from '@/components/advertiser/FormAdvertiserInvoice.vue';
export default {
  name: 'AdvertiserInvoice',
  components: { HeaderAdvertiserInvoice, ListAdvertiserInvoice, FormAdvertiserInvoice }
};
</script>

<style></style>
