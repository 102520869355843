<template>
  <q-dialog v-model="dialogCreateAdvertiserInvoice" @input="setDialogFalse">
    <q-card class="q-pa-md">
      <q-form class="row flex-center q-gutter-md" @submit.prevent="onSubmit">
        <div class="text-h5 col-10">Criar Faturamento:</div>
        <div class="col-5">
          <select-request
            outlined
            endpoint="redirect?app=advertiser&path=/api/get/advertiser"
            option-label="trademark"
            option-value="id"
            map-options
            label="Advertiser"
            clearable
            :v-model="form.advertiser_name"
            @update="
              (value) => {
                [form.advertiser_name, form.advertiser_id, form.cnpj] =
                  value === null ? [null, null, null] : [value.trademark, value.id, value.cnpj];
              }
            "
            :rules="[isRequired]"
          />
        </div>
        <div class="col-5">
          <select-request
            outlined
            :endpoint="`/redirect?app=ADVERTISER&path=/api/get/campaign/advertiser/${form.advertiser_id}`"
            option-label="name"
            option-value="id"
            map-options
            label="Campanha"
            clearable
            :v-model="form.campaign_name"
            @update="
              (value) => {
                [form.campaign_name, form.campaign_id] = value === null ? [null, null] : [value.name, value.id];
              }
            "
            :key="renderSelectRequestCampaign"
            :rules="[isRequired]"
          />
        </div>

        <div class="col-5">
          <q-input :rules="[isRequired]" label="CNPJ" v-model="form.cnpj" mask="##.###.###/####-##" outlined />
        </div>

        <div class="col-5">
          <q-input
            outlined
            readonly
            v-model="notFormattedPeriod"
            class="period_input"
            mask="##/####"
            label="Período"
            @click="$refs.qDateProxy.show()"
            :rules="[isRequired]"
          >
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer" color="primary">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date
                    minimal
                    mask="MM/YYYY"
                    emit-immediately
                    v-model="notFormattedPeriod"
                    default-view="Years"
                    @input="controllerDates"
                    :options="[{ view: ['Years', 'Months'] }]"
                  >
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <div class="col-5"><q-input :rules="[isRequired]" label="PI" v-model="form.pi" outlined /></div>
        <div class="col-5">
          <q-input
            :rules="[isRequired]"
            prefix="R$ "
            mask="#.##"
            reverse-fill-mask
            fill-mask="0"
            label="Custo"
            v-model="form.cost"
            outlined
          />
        </div>
        <div class="col-11">
          <q-select
            outlined
            :options="[
              { label: 'Clientes nacionais', type: 0 },
              { label: 'Clientes internacionais', type: 1 },
              { label: 'CPC nacional', type: 2 },
              { label: 'CPA Nacional', type: 3 },
              { label: 'CPL Nacional', type: 4 },
              { label: 'Mobile nacional', type: 5 },
              { label: 'Latam Nacional', type: 6 },
              { label: 'CPC Internacional', type: 7 },
              { label: 'CPA Internacional', type: 8 },
              { label: 'CPL Internacional', type: 9 },
              { label: 'Mobile Internacional', type: 10 },
              { label: 'LATAM Internacional', type: 11 }
            ]"
            map-options
            option-value="type"
            option-label="label"
            :rules="[isRequired]"
            clearable
            v-model="form.cash_flow"
            multiple
            label="Fluxo de Caixa"
          />
        </div>

        <div class="col-12 row flex-center">
          <div class="col-5">Selecione o tipo:</div>
          <q-radio val="nfs" label="Nota fiscal" v-model="form.type" />
          <q-radio val="invoice" label="Fatura" v-model="form.type" />
        </div>

        <div v-if="form.type == 'invoice'" class="q-gutter-md col-12 row flex-center">
          <div class="col-5">
            <date-input
              :rules="[isRequired]"
              label="Data de emissão"
              :v-model="invoice_form.emission_date"
              @update="(value) => (invoice_form.emission_date = value)"
              outlined
            />
          </div>
          <div class="col-5">
            <date-input
              :rules="[isRequired]"
              label="Data de vencimento"
              :v-model="invoice_form.due_date"
              @update="(value) => (invoice_form.due_date = value)"
              outlined
            />
          </div>
          <div class="col-5">
            <q-input
              outlined
              prefix="R$ "
              mask="#.##"
              reverse-fill-mask
              fill-mask="0"
              v-model="invoice_form.value_to_invoice"
              :rules="[isRequired]"
              label="Valor a faturar"
            />
          </div>

          <div class="col-5">
            <q-select outlined label="Moeda" :options="['BRL']" :rules="[isRequired]" v-model="invoice_form.currency" />
          </div>

          <div class="col-12 row flex-center">
            <div class="col-5">É Nacional:</div>
            <q-radio :val="true" label="Sim" v-model="invoice_form.is_national" />
            <q-radio :val="false" label="Não" v-model="invoice_form.is_national" />
          </div>

          <div class="col-10">
            <q-select
              :options="[{ value: 'virtual_space_location', label: 'Locação de Espaço Virtual' }]"
              outlined
              label="Operação"
              map-options
              v-model="invoice_form.operation"
              readonly
              :rules="[isRequired]"
            />
          </div>

          <div class="col-5"></div>

          <div class="col-12 flex-center row q-gutter-md q-mb-md">
            <div class="text-subtitle1 col-10 text-left">Serviços:</div>
            <q-form ref="serviceForm" @submit.prevent="addInvoiceService" class="col-11 row q-gutter-md">
              <div class="col-5">
                <q-input
                  label="Valor Unitário"
                  v-model="new_invoice_service.unitary_value"
                  prefix="R$ "
                  mask="#.##"
                  reverse-fill-mask
                  fill-mask="0"
                  lazy-rules="ondemand"
                  :rules="[isRequiredInvoiceService]"
                  dense
                  outlined
                />
              </div>

              <div class="col-5">
                <q-select
                  label="Tipo de Serviço"
                  :options="serviceOptions"
                  option-value="type"
                  option-label="label"
                  map-options
                  emit-value
                  v-model="new_invoice_service.type"
                  :rules="[isRequiredWithZero]"
                  lazy-rules="ondemand"
                  dense
                  outlined
                />
              </div>
              <div class="col-5">
                <q-input
                  v-model="new_invoice_service.quantity"
                  :rules="[isRequiredInvoiceService]"
                  label="Quantidade"
                  type="number"
                  lazy-rules="ondemand"
                  outlined
                  dense
                />
              </div>
              <div class="col-5">
                <q-select
                  dense
                  outlined
                  label="Centro de Custo"
                  lazy-rules="ondemand"
                  :options="['Afilio', 'Hi-Midia']"
                  :rules="[isRequiredInvoiceService]"
                  v-model="new_invoice_service.cost_center"
                />
              </div>
              <div class="col-10 text-right">
                <q-btn type="submit" rounded color="positive" unelevated><q-icon name="mdi-plus" /></q-btn>
              </div>

              <q-table
                class="col-11"
                hide-bottom
                flat
                bordered
                v-if="invoice_form.services.length > 0"
                :data="
                  invoice_form.services.map(function (val, index) {
                    return { ...val, id: index };
                  })
                "
                :columns="[
                  {
                    name: 'tipo',
                    label: 'Descrição',
                    align: 'center',
                    field: 'type'
                  },
                  {
                    name: 'valor_unitario',
                    label: 'Valor Unitário',
                    align: 'center',
                    field: 'unitary_value'
                  },
                  {
                    name: 'cost_center',
                    label: 'Centro de Custo',
                    align: 'center',
                    field: 'cost_center'
                  },
                  {
                    name: 'quantidade',
                    label: 'Quantidade',
                    align: 'center',
                    field: 'quantity'
                  },
                  {
                    name: 'cancel',
                    label: 'Remover',
                    align: 'center'
                  }
                ]"
              >
                <template v-slot:body-cell-valor_unitario="props">
                  <q-td :props="props"> R$ {{ props.row.unitary_value }} </q-td>
                </template>

                <template v-slot:body-cell-tipo="props">
                  <q-td :props="props"> {{ serviceOptions.find((val) => val.type == props.row.type).label }} </q-td>
                </template>
                <template v-slot:body-cell-cancel="props">
                  <q-td :props="props">
                    <q-btn flat @click="invoice_form.services.splice(props.row.id, 1)"
                      ><q-icon color="negative" name="mdi-cancel" /></q-btn
                  ></q-td>
                </template>
              </q-table>
            </q-form>
            <div class="col-8 flex-center">
              <q-input
                style="width: auto; height: 0px"
                no-error-icon
                readonly
                borderless
                :error="invoice_form.services.length <= 0"
                bottom-slots
              >
                <template v-slot:error
                  ><div class="text-center">É necessário ao menos um serviço em cada fatura!</div></template
                >
              </q-input>
            </div>
          </div>

          <div class="col-11">
            <q-input type="textarea" outlined label="Observação" v-model="invoice_form.observation" />
          </div>

          <div class="col-11 text-right q-gutter-sm">
            <q-btn unelevated color="negative" @click="setDialogFalse(false)">Cancelar</q-btn>
            <q-btn type="submit" unelevated color="positive">Finalizar</q-btn>
          </div>
        </div>
        <div v-else class="q-gutter-md col-12 row flex-center">
          <div class="col-5">
            <q-input :rules="[isRequired]" outlined label="Tipo da NF" v-model="nf_form.nf_type" readonly />
          </div>
          <div class="col-5">
            <q-select
              :options="[{ value: 'virtual_space_location', label: 'Locação de Espaço Virtual' }]"
              outlined
              label="Natureza da operação"
              map-options
              v-model="nf_form.operation_nature"
              readonly
              :rules="[isRequired]"
            />
          </div>
          <div class="col-5">
            <q-select
              :options="[{ value: 'service_provision', label: 'Prestação de Serviço' }]"
              outlined
              label="Tipo de serviço da NF"
              map-options
              v-model="nf_form.nf_service_type"
              readonly
              :rules="[isRequired]"
            />
          </div>

          <div class="col-5">
            <q-input
              outlined
              label="Red. ISS"
              mask="% ##.##"
              fill-mask="0000"
              unmasked-value
              reverse-fill-mask
              :rules="[isRequired]"
              v-model="nf_form.red_iss"
            />
          </div>

          <div class="col-12 flex-center row q-gutter-md q-mb-md">
            <div class="text-subtitle1 col-4">Plano de pagamento:</div>
            <q-form ref="paymentPlanParcelForm" @submit.prevent="addPaymentPlanParcel" class="col-11 row q-gutter-md">
              <div class="col-4">
                <q-input
                  label="Valor"
                  prefix="R$ "
                  mask="#.##"
                  reverse-fill-mask
                  fill-mask="0"
                  v-model="new_payment_plan_parcel.value"
                  :rules="[isRequiredPaymentPlan]"
                  dense
                  outlined
                />
              </div>
              <div class="col-4">
                <date-input
                  label="Data"
                  :v-model="new_payment_plan_parcel.date"
                  @update="(value) => (new_payment_plan_parcel.date = value)"
                  :rules="[isRequiredPaymentPlan]"
                  dense
                  outlined
                />
              </div>
              <div class="col-2 text-right">
                <q-btn type="submit" rounded color="positive" unelevated><q-icon name="mdi-plus" /></q-btn>
              </div>

              <q-table
                class="col-11"
                hide-bottom
                flat
                bordered
                v-if="nf_form.payment_plan.length > 0"
                :data="
                  nf_form.payment_plan.map(function (val, index) {
                    return { ...val, id: index };
                  })
                "
                :columns="[
                  {
                    name: 'Valor',
                    label: 'Valor',
                    align: 'center',
                    field: 'value'
                  },
                  {
                    name: 'Data',
                    label: 'Data',
                    align: 'center',
                    field: 'date'
                  },
                  {
                    name: 'cancel',
                    label: 'Remover',
                    align: 'center'
                  }
                ]"
              >
                <template v-slot:body-cell-Valor="props">
                  <q-td :props="props"> R$ {{ props.row.value }} </q-td>
                </template>
                <template v-slot:body-cell-cancel="props">
                  <q-td :props="props">
                    <q-btn flat @click="nf_form.payment_plan.splice(props.row.id, 1)"
                      ><q-icon color="negative" name="mdi-cancel" /></q-btn
                  ></q-td>
                </template>
              </q-table>
            </q-form>
            <div class="col-8 flex-center">
              <q-input
                style="width: auto; height: 0px"
                no-error-icon
                readonly
                borderless
                :error="nf_form.payment_plan.length <= 0"
                bottom-slots
              >
                <template v-slot:error
                  ><div class="text-center">É necessário ao menos uma parcela do plano de pagamento!</div></template
                >
              </q-input>
            </div>
          </div>

          <div class="col-11">
            <q-input type="textarea" outlined label="Informações Adicionais" v-model="nf_form.aditional_info" />
          </div>

          <div class="col-11 text-right q-gutter-sm">
            <q-btn unelevated color="negative" @click="setDialogFalse(false)">Cancelar</q-btn>
            <q-btn type="submit" unelevated color="positive">Finalizar</q-btn>
          </div>
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import FinancialMixin from '@/mixins/FinancialMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest';
import DateInput from '@/widgets/inputsGlobal/DateInput';

export default {
  name: 'FormAdvertiserInvoice',
  mixins: [FinancialMixin],
  components: { SelectRequest, DateInput },
  data() {
    return {
      serviceOptions: [
        { label: 'Locação de espaço virtual nacional', type: 0 },
        { label: 'Locação d eespaço virtual exterior', type: 1 },
        { label: 'CPC nacional', type: 2 },
        { label: 'CPA Nacional', type: 3 },
        { label: 'CPL Nacional', type: 4 },
        { label: 'Mobile nacional', type: 5 },
        { label: 'Latam Nacional', type: 6 },
        { label: 'CPC Internacional', type: 7 },
        { label: 'CPA Internacional', type: 8 },
        { label: 'CPL Internacional', type: 9 },
        { label: 'Mobile Internacional', type: 10 },
        { label: 'LATAM Internacional', type: 11 }
      ],
      renderSelectRequestCampaign: 0,
      notFormattedPeriod: null,
      form: {
        advertiser_id: null,
        advertiser_name: null,
        campaign_id: null,
        campaign_name: null,
        period: null,
        pi: null,
        cnpj: null,
        cost: null,
        cash_flow: null,
        type: 'invoice'
      },
      invoice_form: {
        value_to_invoice: null,
        emission_date: Date.now(),
        due_date: null,
        currency: null,
        is_national: false,
        operation: 'virtual_space_location',
        cost_center: 'Afilio',
        observation: null,
        services: []
      },
      nf_form: {
        nf_type: 'NFSE',
        operation_nature: 'virtual_space_location',
        nf_service_type: 'service_provision',
        aditional_info: null,
        red_iss: null,
        payment_plan: []
      },

      new_payment_plan_parcel: {
        value: null,
        date: null
      },
      isCreatingService: false,
      isCreatingPaymentPlanParcel: false,
      new_invoice_service: {
        unitary_value: null,
        quantity: null,
        cost_center: '',
        type: null
      },

      countclickedDate: 0
    };
  },

  watch: {
    async 'form.advertiser_id'() {
      if (!this.form.advertiser_id) {
        this.form.cnpj = null;
      }
      this.form.campaign_id = null;
      this.form.campaign_name = null;

      // Mudando valor que será o key do componente select request de campanha para que os models do componente possam ser atualizados
      this.renderSelectRequestCampaign++;
    }
  },

  methods: {
    async onSubmit() {
      // Fazendo validação aqui pois a mensagem de erro que aparece no front com o "dumb" input não impede o evento submit do formulário
      if (
        (this.form.type == 'nfs' && this.nf_form.payment_plan.length == 0) ||
        (this.form.type == 'invoice' && this.invoice_form.services.length == 0)
      ) {
        return;
      }

      let creationData =
        this.form.type == 'nfs' ? { ...this.form, ...this.nf_form } : { ...this.form, ...this.invoice_form };

      var splitedDate = this.notFormattedPeriod.split('/');

      creationData.period = `${splitedDate[1]}-${splitedDate[0]}-01`;
      creationData.emission_date = this.$moment(creationData.emission_date).format('YYYY-MM-DD');

      try {
        this.onLoading(true);
        const { data, status } = await this.createAdvertiserInvoice(creationData);
        if (status == 201) {
          this.successNotify(data.message);
          this.ActionSetAdvertiserInvoiceUpdate(true);
          this.setDialogFalse(false);
        }
      } catch (error) {
        if (Array.isArray(error.errors)) {
          this.errorNotify(error.errors[0]);
        } else {
          this.errorNotify(error);
        }
      } finally {
        this.onLoading(false);
      }
    },

    async addPaymentPlanParcel() {
      this.isCreatingPaymentPlanParcel = true;

      var isValid = await this.$refs.paymentPlanParcelForm.validate(true);

      this.isCreatingPaymentPlanParcel = false;

      if (!isValid) return;

      let newParcel = { date: this.new_payment_plan_parcel.date, value: this.new_payment_plan_parcel.value };
      this.nf_form.payment_plan.push(newParcel);
      this.new_payment_plan_parcel = {
        value: null,
        date: null
      };
    },

    async addInvoiceService() {
      this.isCreatingService = true;

      var isValid = await this.$refs.serviceForm.validate(true);

      this.isCreatingService = false;

      if (!isValid) return;

      this.invoice_form.services.push({ ...this.new_invoice_service });
      this.new_invoice_service = {
        unitary_value: null,
        quantity: '',
        cost_center: '',
        type: ''
      };
    },

    isRequiredWithZero(val) {
      return val === 0 || this.isRequiredInvoiceService(val);
    },

    setDialogFalse(val) {
      if (!val) {
        this.ActionSetDialogCreateAdvertiserInvoice(false);
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },

    isRequiredInvoiceService(val) {
      //utilizando essa variável isCreatingService pois queremos que essa validação seja disparada apenas quando
      //o formulário de um novo serviço está sendo utilizado, e não quando o formulário do faturamento inteiro está sendo utilizado
      return !this.isCreatingService || !!val || this.$t('is_required');
    },

    isRequiredPaymentPlan(val) {
      //utilizando essa variável isCreatingPaymentPlanParcel pois queremos que essa validação seja disparada apenas quando
      //o formulário de nova parcela do plano de pagamento está sendo utilizado, e não quando o formulário do faturamento inteiro está sendo utilizado
      return !this.isCreatingPaymentPlanParcel || !!val || this.$t('is_required');
    },

    controllerDates() {
      this.countclickedDate += 1;
      if (this.countclickedDate >= 2) {
        this.countclickedDate = 0;
        this.$refs.qDateProxy.hide();
      }
    }
  }
};
</script>

<style>
.q-field--outlined.q-field--readonly .q-field__control:before {
  border-style: solid;
}
</style>
