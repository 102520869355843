<template>
  <q-table :pagination="pagination" flat :data="advertiserInvoices" :columns="columns" bordered>
    <template v-slot:body-cell-type="props">
      <q-td :props="props">
        {{ props.row.type == 'nfs' ? 'Nota Fiscal' : 'Fatura' }}
      </q-td>
    </template>

    <template v-slot:body-cell-value="props">
      <q-td :props="props">
        R$ {{ props.row.type == 'nfs' ? props.row.payment_plan_parcels_sum_value : props.row.value_to_invoice }}
      </q-td>
    </template>
    <template v-slot:body-cell-invoice="props">
      <q-td :props="props">
        <q-btn
          flat
          :disable="props.row.type == 'nfs'"
          color="primary"
          @click="showInvoice(props.row.id)"
          icon="fas fa-file-invoice-dollar"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-cancel="props">
      <q-td :props="props">
        <q-btn flat color="red" @click="cancelInvoice(props.row.id)" icon="fas fa-ban" />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import FinancialMixin from '@/mixins/FinancialMixin.js';
import FinanceService from '@/services/FinanceService.js';
export default {
  name: 'ListAdvertiserInvoice',
  mixins: [FinancialMixin, FinanceService],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'type',
          label: 'Tipo',
          align: 'center',
          field: 'type'
        },
        {
          name: 'advertiser_name',
          required: true,
          label: 'Anunciante ',
          align: 'left',
          field: 'advertiser_name'
        },
        {
          name: 'period',
          required: true,
          label: 'Período',
          align: 'left',
          field: 'periodo'
        },
        {
          name: 'invoice',
          required: true,
          label: 'Fatura',
          align: 'center',
          field: 'id'
        },
        {
          name: 'value',
          required: true,
          label: 'Valor',
          align: 'left',
          field: 'cost'
        },
        {
          name: 'cancel',
          required: true,
          label: 'Cancelar',
          align: 'center',
          field: 'id'
        }
      ]
    };
  },
  methods: {
    async showInvoice(id) {
      let route = this.$router.resolve({ path: `/advertiser/invoice/pdf/${id}` });
      window.open(route.href, '_blank');
    },

    async cancelInvoice(id) {
      try {
        this.onLoading(true);
        const { data, status } = await this.deleteAdvertiserInvoice(id);
        if (status == 200) {
          this.successNotify(data.message);
          this.ActionSetAdvertiserInvoiceUpdate(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
